import React, { useState } from "react";
import axios from "axios";

const DataTable = ({ data, currentPage, itemsPerPage }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "Asia/Kolkata",
    };
    return new Date(dateTimeString).toLocaleString("en-IN", options);
  };

  const openModal = (row) => {
    setSelectedRow(row);
    setCurrentComment(row.comments || "");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
    setCurrentComment("");
  };

  const handleCommentChange = (e) => {
    setCurrentComment(e.target.value);
  };

  const handleSaveComment = async () => {
    if (selectedRow) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/${selectedRow.id}`,
          {
            comments: currentComment,
          }
        );

        if (response.status === 200) {
          selectedRow.comments = currentComment;
          closeModal();
        } else {
          console.error("Failed to save comment");
        }
      } catch (error) {
        console.error("Error saving comment:", error);
      }
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border-collapse border border-gray-200">
        <thead>
          <tr className="bg-gray-100">
            <th className="py-3 px-6 text-left border-b border-gray-200">
              S.No
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">ID</th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Name
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Phone
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Email
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Status
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Razorpay Order ID
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Plan ID
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Plan Name
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Product Name
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Created At
            </th>
            <th className="py-3 px-6 text-left border-b border-gray-200">
              Comments
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              <td className="py-4 px-6 border-b border-gray-200">
                {(currentPage - 1) * itemsPerPage + index + 1}
              </td>
              <td className="py-4 px-6 border-b border-gray-200">{row.id}</td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.firstName}
              >
                {row.firstName}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.phone}
              >
                {row.phone}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.email}
              >
                {row.email}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.status}
              >
                {row.status}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.razorpayOrderId}
              >
                {row.razorpayOrderId}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.planId}
              >
                {row.planId}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-md"
                title={row.planName}
              >
                {row.planName}
              </td>
              <td
                className="py-4 px-6 border-b border-gray-200 truncate max-w-xs"
                title={row.productName}
              >
                {row.productName}
              </td>
              <td className="py-4 px-6 border-b border-gray-200">
                {formatDate(row.createdAt)}
              </td>
              <td className="py-4 px-6 border-b border-gray-200 max-w-xs">
                <div className="truncate max-w-xs" title={row.comments}>
                  {row.comments}
                </div>
                <button
                  className="ml-2 p-2 bg-blue-500 text-white rounded"
                  onClick={() => openModal(row)}
                >
                  {row.comments ? "Edit" : "Add Comment"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
          onClick={closeModal}
        >
          <div
            className="bg-white p-6 rounded shadow-md w-1/2"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-xl mb-4">
              {selectedRow?.comments ? "Edit Comment" : "Add Comment"}
            </h2>
            <textarea
              className="w-full p-2 border rounded"
              value={currentComment}
              onChange={handleCommentChange}
              rows="5"
            />
            <div className="mt-4 flex justify-end">
              <button
                className="p-2 bg-gray-500 text-white rounded mr-2"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-blue-500 text-white rounded"
                onClick={handleSaveComment}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataTable;
