import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePrevClick = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  return (
    <div className="pagination-container flex items-center justify-center mt-8 space-x-2">
      <button
        onClick={handlePrevClick}
        disabled={currentPage === 1}
        className="pagination-button p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 transition"
      >
        Previous
      </button>

      <div className="pagination-info flex items-center">
        <span className="text-lg font-semibold">
          Page {currentPage} of {totalPages}
        </span>
      </div>

      <button
        onClick={handleNextClick}
        disabled={currentPage === totalPages}
        className="pagination-button p-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:opacity-50 transition"
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
