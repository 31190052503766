import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "./components/DataTable";
import LoginPage from "./components/LoginPage";
import Pagination from "./components/Pagination";
import Filter from "./components/Filter";
import Header from "./components/Header";

const App = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    status: "",
    dateFrom: "",
    dateTo: "",
    productName: "", // Add productName to the filter state
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleClearFilters = () => {
    setFilter({ status: "", dateFrom: "", dateTo: "", productName: "" }); // Reset productName
    setSearchTerm("");
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      const fetchData = async () => {
        try {
          const response = await axios.get(apiUrl);
          const logs = response.data.logs || [];
          setData(logs);
          setFilteredData(logs);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [isLoggedIn, apiUrl]);

  useEffect(() => {
    const { status, dateFrom, dateTo, productName } = filter;
    const filtered = data.filter((item) => {
      const statusMatch = status ? item.status === status : true;
      const dateFromMatch = dateFrom
        ? new Date(item.createdAt) >= new Date(dateFrom)
        : true;
      const dateToMatch = dateTo
        ? new Date(item.createdAt) <= new Date(dateTo)
        : true;
      const productNameMatch = productName ? item.productName === productName : true;
      const searchTermMatch = searchTerm
        ? (item.firstName &&
            item.firstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (item.phone &&
            item.phone.toLowerCase().includes(searchTerm.toLowerCase()))
        : true;
      return statusMatch && dateFromMatch && dateToMatch && productNameMatch && searchTermMatch;
    });

    setFilteredData(filtered);
    setCurrentPage(1); // Reset the current page when the filter changes
  }, [filter, searchTerm, data]);

  const handleDateChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleLogin = (status) => {
    setIsLoggedIn(status);
    localStorage.setItem("isLoggedIn", status);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  const initiatedCount = filteredData.filter(
    (item) => item.status === "initiated"
  ).length;
  const completedCount = filteredData.filter(
    (item) => item.status === "completed"
  ).length;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (!isLoggedIn) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Header
        initiatedCount={initiatedCount}
        completedCount={completedCount}
        handleLogout={handleLogout}
      />
      <div className="container mx-auto flex-grow">
        <Filter
          filter={filter}
          handleDateChange={handleDateChange}
          setFilter={setFilter}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleClearFilters={handleClearFilters}
          filteredData={filteredData}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
        <DataTable
          data={currentItems}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={filteredData.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default App;
