import React from "react";

const Header = ({ initiatedCount, completedCount, handleLogout }) => {
  return (
    <div className="mb-4 flex items-center justify-between">
      <h1 className="text-2xl font-bold mb-10 mt-5 text-center ml" style={{marginLeft: "70px"}}>
        User Logs of Landing Pages
      </h1>
      <div className="flex items-center">
        <h1 className="ml-14 text-xl">
          Completed:
          <span className="text-green-500 ml-2 font-bold">
            {completedCount}
          </span>
        </h1>
        <h1 className="ml-4 text-xl">
          Initiated:
          <span className="text-red-500 ml-2 font-bold">{initiatedCount}</span>
        </h1>
      </div>
      <button
        className="p-2 bg-red-500 text-white rounded"
        onClick={handleLogout} style={{marginRight: "70px"}}
      >
        Log Out
      </button>
    </div>
  );
};

export default Header;
