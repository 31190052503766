import React, { useState, useEffect } from "react";
// import { saveAs } from "file-saver";
// import Papa from "papaparse";

const Filter = ({
  filter,
  handleDateChange,
  setFilter,
  searchTerm,
  setSearchTerm,
  handleClearFilters,
//   filteredData,
//   currentPage,
//   itemsPerPage,
}) => {
  //   const formatDateToIST = (dateTimeString) => {
  //     const options = {
  //       year: "numeric",
  //       month: "numeric",
  //       day: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       timeZone: "Asia/Kolkata",
  //     };
  //     return new Date(dateTimeString).toLocaleString("en-IN", options);
  //   };

  //   const handleDownload = () => {
  //     const csvData = filteredData.map((item, index) => ({
  //       SerialNumber: index + 1 + (currentPage - 1) * itemsPerPage,
  //       ID: item.id,
  //       FirstName: item.firstName,
  //       Phone: item.phone,
  //       Status: item.status,
  //       RazorpayOrderID: item.razorpayOrderId,
  //       PlanID: item.planId,
  //       Email: item.email,
  //       CreatedAt: formatDateToIST(item.createdAt),
  //     }));

  //     const csv = Papa.unparse(csvData);
  //     const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //     sav
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL) 
      .then((response) => response.json())
      .then((data) => {
        const productNames = data.logs.map((log) => log.productName);
        const uniqueProductNames = [...new Set(productNames)];
        setProducts(uniqueProductNames);
      })
      .catch((error) => console.error("Error fetching product names:", error));
  }, []);

  return (
    <div className="mb-4 flex items-center justify-between">
      <div className="flex items-center">
        <div className="mr-4">
          <label htmlFor="dateFrom" className="font-bold">
            From:
          </label>
          <input
            type="date"
            name="dateFrom"
            className="p-2 border rounded"
            value={filter.dateFrom}
            onChange={handleDateChange}
            placeholder="From"
          />
        </div>
        <div className="mr-4">
          <label htmlFor="dateTo" className="font-bold">
            To:
          </label>
          <input
            type="date"
            name="dateTo"
            className="p-2 border rounded"
            value={filter.dateTo}
            onChange={handleDateChange}
            placeholder="To"
          />
        </div>
        <div className="mr-4">
          <select
            className="p-2 border rounded"
            value={filter.status}
            onChange={(e) => setFilter({ ...filter, status: e.target.value })}
          >
            <option value="">Status</option>
            <option value="completed">Completed</option>
            <option value="initiated">Initiated</option>
          </select>
        </div>
        <div className="mr-4">
          <select
            className="p-2 border rounded"
            value={filter.productName}
            onChange={(e) =>
              setFilter({ ...filter, productName: e.target.value })
            }
          >
            <option value="">Product Name</option>
            {products.map((product, index) => (
              <option key={index} value={product}>
                {product}
              </option>
            ))}
          </select>
        </div>
        <div className="mr-4">
          <input
            type="text"
            className="p-2 border rounded"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <button
          className="p-2 bg-blue-500 text-white rounded"
          onClick={handleDownload}
        >
          Download CSV
        </button> */}
        <button
          onClick={handleClearFilters}
          className="p-2 bg-gray-800 text-white rounded ml-16"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default Filter;
